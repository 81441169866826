.main-overview {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(265px, 1fr)); /* Where the magic happens */
  grid-auto-rows: 94px;
  grid-gap: 20px;
  margin: 20px;
}

.overviewcard {
  align-items: center;
  background-color: #d3d3;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}
