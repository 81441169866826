* {
  box-sizing: border-box;
  font-family: 'Roboto', sans-serif;
  margin: 0;
  padding: 0;
}

button {
  border-radius: 10px;
  color:#fff;
  font-size: 1rem;
  font-weight: 600;
  height: 30px;
  width: 305px;  

  &:hover {
    background-color:#000;
  }
}