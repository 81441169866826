.stu-login-brand {
  background-image: url("../../../../assets/resources/img/Login/brand.jpg");
  
  /* Full height */
  height: 100vh;

  /* Center and scale the image nicely */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
