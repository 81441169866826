.main-cards {
  column-count: 2;
  column-gap: 20px;
  margin: 20px;
}

.card {
  align-items: center;
  background-color: #82bef6;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  padding: 24px;
  width: 100%;
  -webkit-column-break-inside: avoid;
}

/* Force varying heights to simulate dynamic content */
.card:first-child {
  height: 310px;
}

.card:nth-child(2) {
  height: 130px;
}

.card:nth-child(3) {
  height: 160px;
}