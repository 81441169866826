.stu-login-container {
  align-items: center;
  display: flex;
  justify-content: space-around;
  min-height: 100vh;
  width: 100%;

  &__item{
    flex: 0 1 50%;
    max-width: 50%;
  }
}