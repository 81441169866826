// Make our dashboard responsive in a mobile-first format
.grid-container {
  display: grid;
  grid-template-columns: 1fr; /* Side nav is hidden on mobile */
  grid-template-rows: 50px 1fr 50px;
  grid-template-areas:
    'header'
    'main'
    'footer';
  height: 100vh;
}

.sidebar {
  background-color: #394263;
  display: none;
  grid-area: sidebar;
}

.main-cards {
  column-count: 1;
  column-gap: 20px;
  margin: 20px;
}

// ==== Mobile First Approach End ===

 /* Non-mobile styles, 750px breakpoint */
 @media only screen and (min-width: 46.875em) { 
  .grid-container {
    grid-template-columns: 250px 1fr;
    grid-template-areas: 
    "sidebar header"
    "sidebar main"
    "sidebar footer";
  }

  .sidebar {
    background-color: #394263;
    display: flex;
    flex-direction: column;
    grid-area: sidebar;
  }
 }

 /* Medium-sized screen breakpoint (tablet, 1050px) */
 @media only screen and (min-width: 65.625em) {
  /* Break out main cards into two columns */
  .main-cards {
    column-count: 2;
  }
}

.header, .footer {
  align-items: center;
  background-color: #648ca6;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.header {
  grid-area: header;
}

.main {
  background-color: #8fd4d9;
  grid-area: main;
}

.footer {
  grid-area: footer;
}
