.sidebar__list {
  margin-top: 85px;
  list-style-type: none;
  padding: 0;
}

.sidebar__list-item {
  color: #ddd;
  padding: 20px 20px 20px 40px;
}

.sidebar__list-item:hover {
  background-color: rgba(255, 255, 255, 0.2);
  cursor: pointer;
}