.stu-login-form-container {
  align-items: center;
  display: flex;
  flex-direction: column;

  &__item {
    height: 100%;
    width: 100%;
    margin-bottom: 10px;

    &--signup {
      background-color: rgba(12, 121, 12, 0.829); 
    }

    &--login {
      background-color: rgba(19, 12, 121, 0.829);
    }
  }
}
